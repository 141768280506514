import gtmUtil from '@/utils/gtm-util'

// This function will be used on Epiforms submit. That's why it should have global context.
window.formSubmit = (title, container, hem) => {
  gtmUtil.formSubmit(title, container, hem)
}

const textareaCounter = (container = document) => {
  const textareas = container.querySelectorAll('textarea')
  textareas.forEach((textarea) => {
    if (!textarea.maxLength) {
      return
    }
    const counters = textarea.nextElementSibling
    if (counters) {
      const currentCount = counters.querySelector('.js-current')
      textarea.addEventListener('keyup', () => {
        currentCount.innerHTML = textarea.value.length
      })
    }
  })
}

// eslint-disable-next-line import/prefer-default-export
export { textareaCounter }
