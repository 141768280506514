import gtmUtil from '@/utils/gtm-util'

const trackRecipeImpression = ({
  element,
  trackingFunction,
  recipeName
}) => {
  let timeoutId = null
  let observer = null

  const handleVisibilityChange = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
        if (!timeoutId) {
          timeoutId = setTimeout(() => {
            trackingFunction(recipeName)
            observer.disconnect()
          }, 10000)
        }
      } else if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }
    })
  }

  observer = new IntersectionObserver(handleVisibilityChange, {
    threshold: [0.5]
  })

  observer.observe(element)
}

const trackRecipeInstructionsImpression = () => {
  const element = document.querySelector('.js-recipe-instructions')
  if (!element) return
  const { recipeName } = element.dataset
  const trackingFunction = gtmUtil.trackRecipeInstructions10sec

  trackRecipeImpression({
    element,
    trackingFunction,
    recipeName
  })
}

// eslint-disable-next-line import/prefer-default-export
export { trackRecipeImpression, trackRecipeInstructionsImpression }
